/* global dataLayer */
import config from '../config/main'
/**
 *
 * @param {string} trigger - element which holds the extra data attributes
 *
 */

// Can be used with makeGtmCall(el)
// Either add as data attributes in the markup el has:
//  - <a href="#" data-gtm-event="pdf-download" data-gtm-name="example.pdf"> download </a>
// or adding in the js before calling makeGtmCall(el)
//  - el.dataset.gtmEvent = 'pdf-download'; el.dataset.gtmName = 'example.pdf'
// every data attribute with "gtm" will go in the object

export const makeGtmCall = (trigger) => {
    const object = {}

    const keys = Object.keys(trigger.dataset)

    for (const key of keys) {
        if (key.includes('gtm')) {
            const cleanKey = key.replace('gtm', '')
            const formattedKey =
                cleanKey.charAt(0).toLowerCase() + cleanKey.slice(1)

            object[formattedKey] = trigger.dataset[key]
        }
    }

    if (config.trackingDebug) {
        console.info(object)
    }
    if (
        typeof dataLayer === 'undefined' ||
        typeof trigger?.dataset?.gtmEvent === 'undefined'
    ) {
        console.warn('gtm is not defined')
        return false
    }

    dataLayer.push(object)
}
