import { _ } from '../utils'

export default () => {
    const menuButton = _('cms-block-menu', 'target').node()
    const menuLinks = _('cms-blocks-menu-link').nodes()

    const closeMenu = (e) => {
        menuButton.dispatchEvent(new Event('click'))
        document.querySelector(e.currentTarget.dataset.target).scrollIntoView({
            behavior: 'smooth'
        })
    }

    menuLinks.forEach((menuLink) => {
        menuLink.addEventListener('click', closeMenu)
    })
}
