import { _ } from '../utils'

export default class SmoothScroll {
    constructor(anchor) {
        anchor.addEventListener('click', this.scroll)
    }

    scroll = (e) => {
        e.preventDefault()
        document.querySelector(e.target.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        })
    }
}

