import { _, removeClasses } from '../utils'
import config from '../config/main'

class Menu {
    constructor() {
        this.subTrigger = _('sub-trigger', 'element').nodes()
        this.backTrigger = _('back-trigger', 'element').nodes()
        this.subNav = _('sub-nav', 'element').nodes()
        this.menuUnFocus = _('menu-unfocus', 'element-2').nodes()
        this.menuLink = _('has-subnav', 'element').nodes()
        this.navFooter =  _('nav-footer', 'element').node()

        this.navTabletBreak = window.matchMedia(
            `(max-width: 1025px)`
        )
        this.navMobileBreak = window.matchMedia(
            `(max-width: ${config.breakpoint.small}px)`
        )

        this.toggleNavFunctionality()
        this.navMobileBreak.addListener(this.toggleNavFunctionality)
        this.navTabletBreak.addListener(this.toggleNavFunctionality)
        
        this.subTrigger.map((el) => {
            el.addEventListener('click', this.addActiveClass)
        })
        this.backTrigger.map((el) => {
            el.addEventListener('click', this.removeActiveClass)
        })

        this.classes = {
            menuItemActive: 'nav__island--active'
        }
    }

    toggleNavFunctionality = () => {
        let device = 'desktop'
        const isTouch = 'ontouchstart' in document.documentElement

        if (this.navMobileBreak.matches) {
            device = 'mobile'
        }
        if (!this.navMobileBreak.matches && isTouch) {
            device = 'tablet'
        }
        switch (device) {
        case 'mobile':
            this.removeTabAccessibility()
            this.removeTapStoppers()
            break
        case 'tablet':
            this.addTapStoppers()
            break
        case 'desktop':
            this.removeTapStoppers()
            this.addTabAccessibility()
            break
        default:
            console.warn('no device detected')
        }
    }

    removeTabAccessibility = () => {
        this.menuLink.map((el) => {
            el.removeEventListener('focus', this.addActiveClass)
        })
        this.menuUnFocus.map((el) => {
            el.removeEventListener('focus', this.removeActiveClassAccessibility)
        })
    }

    addTabAccessibility = () => {
        this.menuLink.map((el) => {
            el.addEventListener('focus', this.addActiveClass)
        })
        this.menuUnFocus.map((el) => {
            el.addEventListener('focus', this.removeActiveClassAccessibility)
        })
    }

    addTapStoppers = () => {
        this.menuLink.map((el) => {
            el.addEventListener('click', this.preventFirstTap)
        })
    }

    removeTapStoppers = () => {
        this.menuLink.map((el) => {
            el.removeEventListener('click', this.preventFirstTap)
        })
    }
    
    preventFirstTap = (e) => {
        e.preventDefault()
        this.addTapStoppers()
        e.currentTarget.removeEventListener('click', this.preventFirstTap)
    }

    addActiveClass = (e) => {
        e.currentTarget
            .nextElementSibling
            .classList.add('nav__island--active')
        this.navFooter.classList.add('u-hidden')
    }

    removeActiveClass = (e) => {
        e.currentTarget
            .closest(_('sub-nav', 'element').str())
            .classList.remove('nav__island--active')

        this.navFooter.classList.remove('u-hidden')
    }

    removeActiveClassAccessibility = () => {
        removeClasses(this.subNav, this.classes.menuItemActive)
    }
}

export default Menu