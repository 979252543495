/* global dataLayer */
/* global MktoForms2 */
/* global Fill */
/* global $ */
import { _ } from '../utils'
import { makeGtmCall } from '../utils/tracking'

export default class MarketoForm {
    constructor(el) {
        console.warn(
            'Hubspot has replaced marketo, MarketoForm will stop working after end Sep 2023'
        )
        const formElement = el

        const data = {
            formElement: el,
            modalElement: el.dataset.modalElement,
            formContainerId: el.dataset.formContainerId,
            getUrl: el.dataset.getUrl,
            getMunchkinId: el.dataset.getMunchkinId,
            getFormId: el.dataset.getFormId,
            downloadButtonTarget: el.dataset.downloadButtonTarget,
            noModalElement: el.dataset.noModalElement,
            formAction: el.dataset.formAction,
            blockId: el.dataset.blockId,
            assetType: el.dataset.assetType,
            campaignId: el.dataset.campaignId,
            formName: el.dataset.formName,
            event: el.dataset.event,
            fileName: el.dataset.fileName,
            template: el.dataset.template
        }

        const resourceHiddenEl = $(
            `[data-id="${data.getFormId}"].resource-hidden`
        )
        const resourceHiddenElParent = $(
            `[data-element="${resourceHiddenEl.data('parentModalContainer')}"]`
        )
        const resourceHiddenElParentClass = resourceHiddenEl.data(
            'parentModalContainerClass'
        )
        const hideAfterSuccessEl = $(
            `[data-id="${data.getFormId}"].hide-after-success`
        )

        let offset = 0
        const setCookie = (cookieName, value, expiryDays) => {
            const date = new Date()
            date.setTime(date.getTime() + expiryDays * 24 * 60 * 60 * 1000)
            const expires = 'expires=' + date.toUTCString()
            document.cookie = cookieName + '=' + value + '; ' + expires
        }

        const getCookie = (cookieName) => {
            const name = cookieName + '='
            const cookieArray = document.cookie.split(';')

            for (let i = 0; i < cookieArray.length; i++) {
                let cookie = cookieArray[i]
                while (cookie.charAt(0) === ' ') {
                    cookie = cookie.substring(1)
                }
                if (cookie.indexOf(name) === 0) {
                    return cookie.substring(name.length, cookie.length)
                }
            }
            return ''
        }

        MktoForms2.loadForm(
            data.getUrl,
            data.getMunchkinId,
            data.getFormId,
            function (form) {
                const fields = {}

                const cookieValue = getCookie('marketo')

                if (cookieValue) {
                    try {
                        form.vals(JSON.parse(cookieValue))
                    } catch (e) {
                        console.warn(e)
                    }
                }

                if (data.campaignId) {
                    form.vals({ mkoCampaignName: data.campaignId })
                    if (data.utm) {
                        if (data.time) {
                            form.vals({ mkoUTMSource: data.utmSource })
                            form.vals({ mkoUTMMedium: data.utmMedium })
                            form.vals({ mkoUTMContent: data.utmContent })
                            form.vals({ mkoUTMCampaign: data.utmCampaign })
                            form.vals({ mko_UTMCampaign: data.utmCampaign })
                        }
                    }
                }

                form.onSuccess(() => {
                    $(`.${data.formContainerId}`).slideUp()
                    setCookie(`${data.blockId}-form-submitted`, true, 7) // set cookie for gate passed.....

                    if (data.formName === 'Book A Demo') {
                        const industryElement =
                            document.getElementById('Industry')
                        const companyHqElement = document.getElementById(
                            'DSCORGPKG__Company_HQ_Country_Full_Name__c'
                        )
                        const companySizeElement =
                            document.getElementById('Company_Size__c')

                        let industryVal = ''
                        let companyHqVal = ''
                        let companySizeVal = ''

                        if (industryElement) {
                            industryVal = industryElement.value
                        }

                        if (companyHqElement) {
                            companyHqVal = companyHqElement.value
                        }

                        if (companySizeElement) {
                            companySizeVal = companySizeElement.value
                        }

                        dataLayer.push({
                            event: data.event,
                            marketoFormId: data.formContainerId,
                            munchkinId: data.getMunchkinId,
                            formName: data.formName,
                            formIndustry: industryVal,
                            formCompanyHq: companyHqVal,
                            formCompanySize: companySizeVal,
                            blockTemplate: data.template
                        })
                    } else if (data.formName === 'assetDownloadForm') {
                        dataLayer.push({
                            event: data.event,
                            assetType: data.assetType,
                            fileName: data.fileName
                        })
                    } else {
                        dataLayer.push({
                            event: data.event,
                            marketoFormId: data.formContainerId,
                            munchkinId: data.getMunchkinId,
                            formName: data.formName
                        })
                    }

                    resourceHiddenEl.toggleClass('u-hidden')
                    hideAfterSuccessEl.toggleClass('u-hidden')

                    if (resourceHiddenElParent.length) {
                        resourceHiddenElParent.toggleClass(
                            resourceHiddenElParentClass
                        )
                    }

                    form.getFormElem()
                        .find('.mktoField.mktoHasWidth')
                        .each(function () {
                            const input = $(this)
                            fields[input.attr('id')] = input.val()
                        })

                    setCookie('marketo', JSON.stringify(fields), 1)

                    form.getFormElem()
                        .find('.mktoButton')
                        .prop('disabled', false)
                    form.getFormElem()[0].reset()

                    $(formElement).fadeOut(700, function () {
                        const fancyInner = $('.fancybox-inner')
                        let scrollContainer = $('html, body')
                        if (fancyInner.length) {
                            scrollContainer = fancyInner
                        }

                        if (resourceHiddenEl.length) {
                            if (fancyInner.length) {
                                offset = resourceHiddenEl[0].offsetTop - 20
                            } else {
                                offset = resourceHiddenEl.offset().top - 200
                            }

                            scrollContainer.animate({ scrollTop: offset }, 300)
                        }

                        if (hideAfterSuccessEl.length) {
                            if (fancyInner.length) {
                                offset = hideAfterSuccessEl[0].offsetTop - 20
                            } else {
                                offset = hideAfterSuccessEl.offset().top - 200
                            }

                            scrollContainer.animate({ scrollTop: offset }, 300)
                        }
                    })

                    if (data.downloadButtonTarget) {
                        const buttonTarget = $(
                            `[data-element="${data.downloadButtonTarget}"]`
                        )
                        if (buttonTarget.length) {
                            buttonTarget.get(0).click()
                        }
                        const downloadEventName = buttonTarget.data('gtm-event')

                        buttonTarget.on('click', function () {
                            dataLayer.push({
                                event: 'downloadEventName'
                            })
                        })
                    }
                    if (!data.noModalElement) {
                        const noClose = form
                            .getFormElem()
                            .closest(`[data-element="${data.modalElement}"]`)
                            .find('[data-no-close]')

                        if (noClose.length <= 0) {
                            form.getFormElem()
                                .closest(
                                    `[data-element="${data.modalElement}"]`
                                )
                                .find('[data-behaviour="modal"]')
                                .trigger('click')
                            const toggledModal = form
                                .getFormElem()
                                .closest(
                                    `[data-element="${data.modalElement}"]`
                                )
                                .find('[data-behaviour="modal"]')
                                .data('target')
                            const triggeredModal = $(
                                `[data-behaviour="modal"][data-target="${toggledModal}"]`
                            )
                            if (toggledModal) {
                                if (
                                    $(triggeredModal[0]).data(
                                        'banner-video'
                                    ) !== true
                                ) {
                                    triggeredModal[2].click()
                                    triggeredModal.off('click')
                                    triggeredModal[0].setAttribute(
                                        'data-class',
                                        'video--active'
                                    )
                                    triggeredModal[0].setAttribute(
                                        'data-behaviour',
                                        'video'
                                    )
                                    const event = new Event('gated-video', {
                                        bubbles: true
                                    })
                                    triggeredModal[0].dispatchEvent(event)
                                    $(triggeredModal)[0].click()
                                    window.location.reload() // Temp fix for - https://app.asana.com/0/1192348906906154/1200255003661384
                                } else {
                                    const videoModalTarget =
                                        triggeredModal[0].getAttribute(
                                            'data-video-modal-target'
                                        )
                                    triggeredModal[2].click()
                                    triggeredModal[0].setAttribute(
                                        'data-target',
                                        videoModalTarget
                                    )
                                    const event = new Event('gated-video', {
                                        bubbles: true
                                    })
                                    triggeredModal[0].dispatchEvent(event)
                                    $(triggeredModal)[0].click()
                                }
                            }
                        }
                    }
                    return false // Return false to prevent the submission handler from taking the lead to the follow up url
                })
            }
        )
    }
}
