/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import ToggleClass from './modules/toggle-class'
_('toggle-class').nodes().map((el) => new ToggleClass(el))

----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
data-behaviour="toggle-class"    - For setting up the click event
data-target="element"            - For targeting another element
data-class="trigger-class"       - Class to be toggled (optional)
data-blur="true"                 - closes on blur(optional)

----------------------------------------------------------------------------//
Attributes on target element:
----------------------------------------------------------------------------//
data-element="element"           - To match the triggers data-target
data-class="target-class"        - Class to be toggled

*/

import { _ } from '../utils'

class ToggleClass {
    constructor(el) {
        this.el = el
        this.el.dataset.active = false
        this.triggerOnBlur = this.el.dataset.blur
        this.el.addEventListener('click', this.toggleClass)
        if (this.triggerOnBlur === 'true') {
            this.el.addEventListener('blur', this.toggleClass)
        }
    }

    toggleClass = (e) => {
        e.preventDefault()
        const trigger = e.target
        const target = _(trigger.dataset.target, 'element').node()
        const triggerClass = trigger.dataset.class
        const targetClass = target.dataset.class
        let time = 0
        if (e.type === 'blur' && trigger.dataset.active === 'false') {
            return false
        }

        trigger.dataset.active =
            trigger.dataset.active === 'false' ? true : false

        if (e.type === 'blur') {
            time = 100
        }

        setTimeout(() => {
            if (triggerClass) {
                trigger.classList.toggle(triggerClass)
            }
            target.classList.toggle(targetClass)
        }, time)
    }
}

export default ToggleClass
