// e.g
// _('toggle').node().addEventListener('keyup', debounce(this.search, 500))

export const debounce = (callback, wait) => {
    let timeout
    return (...args) => {
        const context = this
        clearTimeout(timeout)
        timeout = setTimeout(() => callback.apply(context, args), wait)
    }
}
