import { _ } from '../utils.js'
import * as Sentry from '@sentry/browser';
import { isBrowserSupported } from '../utils/browser-support.js'

export default () => {
    const env = _('site-info', 'element').node()
    const environment = env?.dataset.environment ?? process.env.NODE_ENV
    const isLocal = environment === 'development' || environment === 'local'
    const loggedIntoCMS = env.dataset.c5 === 'true'

    if (isLocal || !isBrowserSupported() || loggedIntoCMS) {
        return false
    }

    Sentry.init({
        dsn: 'https://1afe65e8492abca166793f6347a52fcc@o4507050262921216.ingest.us.sentry.io/4507294561468416',
        environment: environment,
    })
}
