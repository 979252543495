/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import Sticky from './modules/sticky'
_('sticky').nodes().map((el) => new Sticky(el))
----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
data-element="sticky"             - Sticky outer container
data-element="header"             - Header content to animate 
*/

import { _ } from '../utils'

export default (el) => {
    const header = _('header', 'element').nodeFrom(el)

    const stickScrollpos = 188
    let lastScrollTop = 0

    const stick = () => {
        const newScrollTop = window.pageYOffset || document.documentElement.scrollTop
        if (newScrollTop > lastScrollTop) {
            setTimeout(() => {
                header.style.transform = 'translateY(-110%)'
            }, 300)
            if (newScrollTop > stickScrollpos) {
                header.classList.remove('header--unstuck')
                header.classList.add('header--stuck')
            }
        } else {
            setTimeout(() => {
                header.style.transform = 'translateY(-0)'
            }, 300)
            if (newScrollTop < stickScrollpos) {
                header.classList.remove('header--stuck')
                header.classList.add('header--unstuck')
            }
        }
        lastScrollTop = newScrollTop <= 0 ? 0 : newScrollTop
    }
    document.addEventListener('scroll', stick, { passive: true })
}
