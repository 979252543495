/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import Modal from './modules/modal'
_('modal').nodes().map((el) => new Modal(el))

----------------------------------------------------------------------------
Attributes on trigger element:
----------------------------------------------------------------------------
data-behaviour="modal"    - For setting up the click event
data-target="element"     - For targeting another element
data-hash="pretty name"   - this will be the hash in the url and should only be on open triggers
data-video="true"         - optional, this will trigger a video play

----------------------------------------------------------------------------
Attributes on target element:
----------------------------------------------------------------------------
data-element="element"           - To match the triggers data-target
data-class="target-class"        - Class to be toggled

----------------------------------------------------------------------------
Other attributes
----------------------------------------------------------------------------
data-modal-focus     - first element focused on open

*/

import { _ } from '../utils'
import { makeGtmCall } from '../utils/tracking'
class Modal {
    constructor(el) {
        this.el = el
        this.html = document.querySelector('html')
        this.body = document.body
        this.modalFromHash = true

        history.pushState = ((f) =>
            function pushState() {
                const ret = f.apply(this, arguments)
                window.dispatchEvent(new Event('pushState'))
                window.dispatchEvent(new Event('locationchange'))
                return ret
            })(history.pushState)

        history.replaceState = ((f) =>
            function replaceState() {
                const ret = f.apply(this, arguments)
                window.dispatchEvent(new Event('replaceState'))
                window.dispatchEvent(new Event('locationchange'))
                return ret
            })(history.replaceState)

        window.addEventListener('popstate', () => {
            window.dispatchEvent(new Event('locationchange'))
        })

        this.el.addEventListener('click', this.changeParams)
        window.addEventListener('keyup', this.escapeModal)
        window.addEventListener('locationchange', this.triggerFromParams)
        this.triggerFromParams()
    }

    escapeModal = (e) => {
        const target = document.querySelector('[data-open="true"]')
        if (e.keyCode === 27 && target) {
            const url = new URL(window.location)
            const params = new URLSearchParams(url.search)
            params.delete('modal')
            window.history.pushState(
                null,
                null,
                `${window.location.pathname}${params}${window.location.hash}`
            )
            this.startScroll(target)
        }
    }

    changeParams = (e) => {
        e.preventDefault()
        this.modalFromHash = false
        const targetHash = e.currentTarget.dataset.hash
        const url = new URL(window.location)
        const params = new URLSearchParams(url.search)
        let startParams = '?'

        if (params.has('modal')) {
            params.set('modal', targetHash)
        } else {
            params.append('modal', targetHash)
        }
        if (window.location.href.match('/?').index) {
            startParams = '&'
        }
        if (typeof targetHash === 'undefined') {
            startParams = ''
            params.delete('modal')
        }
        window.history.pushState(
            null,
            null,
            `${window.location.pathname}${startParams}${params}${window.location.hash}`
        )
    }

    triggerFromParams = () => {
        const url = new URL(window.location)
        const params = new URLSearchParams(url.search)
        const openModal = document.querySelector('[data-open="true"]')

        if (openModal && params.get('modal') === null) {
            this.toggleModal(
                document.querySelector(
                    `[data-target="${openModal.dataset.element}"]`
                )
            )
        }
        if (params.get('modal') === '' || params.get('modal') === null) {
            this.startScroll()
            return false
        }

        if (!openModal && params.get('modal') !== null) {
            const triggerEl = document.querySelector(
                `[data-target="${params.get('modal')}"]`
            )
            if (triggerEl) {
                this.toggleModal(triggerEl)
            }
        }
    }

    toggleModal = (el) => {
        const trigger = el
        const target = _(trigger.dataset.target, 'element').node()
        const triggerClass = trigger.dataset.class
        const targetClass = target.dataset.class
        const focusElement = _('', 'modal-focus').nodeFrom(target)

        target.classList.toggle(targetClass)
        const modalOpen = target.classList.contains(targetClass)
        target.dataset.open = modalOpen

        trigger.dataset.gtmEvent = trigger.dataset.gtmEvent || 'modal-open'
        trigger.dataset.gtmName =
            trigger.dataset.gtmName || trigger.dataset.target
        if (modalOpen) {
            makeGtmCall(el)
            if (focusElement) {
                setTimeout(() => {
                    focusElement.focus()
                }, 300)
            }
        } else {
            if (focusElement) {
                focusElement.blur()
            }
        }

        if (triggerClass) {
            trigger.classList.toggle(triggerClass)
        }

        if (
            modalOpen &&
            trigger.dataset.video === 'true' &&
            this.modalFromHash === false
        ) {
            _('video-play').nodeFrom(target).dispatchEvent(new Event('click'))
        }

        if (!modalOpen && trigger.dataset.video === 'true') {
            _('video-pause').nodeFrom(target).dispatchEvent(new Event('click'))
        }

        this.toggleScroll(target)
    }

    stopScroll = (target) => {
        target.dataset.open = true
        target.dataset.position = window.pageYOffset
        this.body.style.height = window.outerHeight
        this.body.style.overflow = 'hidden'
        this.body.classList.add('body--modal')
        window.scrollTo(0, 0)
    }

    startScroll = (target = false) => {
        this.body.style.height = 'auto'
        this.body.style.overflow = 'auto'
        this.body.classList.remove('body--modal')
        if (target) {
            target.dataset.open = false
            window.scrollTo(0, target.dataset.position)
        }
    }

    toggleScroll = (target) => {
        if (!target.dataset.open || target.dataset.open === 'true') {
            this.stopScroll(target)
        } else {
            this.startScroll(target)
        }
    }
}

export default Modal
