/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
yarn add youtube-iframe
import Video from './modules/video'
_('video', 'element').nodes().map((el) => new Video(el))

----------------------------------------------------------------------------
Behaviour attributes:
----------------------------------------------------------------------------
data-behaviour="video-play"      - will hide the image and play the video
data-behaviour="video-pause"     - visually hidden but can be used to trigger elsewhere to pause video
                                   .eg modal close
                                   _('video-pause').nodeFrom(modal).dispatchEvent(new Event('click'))

----------------------------------------------------------------------------
Other attributes:
----------------------------------------------------------------------------
data-element="video"              - is the root element
data-type="video | youtube"       - determines what type the video is and how to handle it
data-active-class="video--active" - the active class toggle on play

----------------------------------------------------------------------------
Youtube specific attributes:
----------------------------------------------------------------------------
data-element="video-player"       - used for tye youtube is the container where the iframe will be appended
data-video-id="yxz"               - the id of the youtube video to be played

*/

import { _ } from '../utils'
import { makeGtmCall } from '../utils/tracking'
import YoutubeVideo from './video-youtube'

export default class Video {
    constructor(el) {
        this.el = el
        this.video = _('video-player', 'element').nodeFrom(this.el)
        this.controls = {
            play: _('video-play').nodeFrom(this.el),
            pause: _('video-pause').nodeFrom(this.el)
        }
        this.activeClass = el.dataset.activeClass

        this.el.dataset.gtmEvent = this.el.dataset.gtmEvent || 'videoPlay'
        if (this.el.dataset.type === 'youtube') {
            this.el.dataset.gtmName =
                this.el.dataset.gtmName || this.video.dataset.videoId
            new YoutubeVideo(this)
        }

        if (this.el.dataset.type === 'video') {
            this.player = this.video
            this.el.dataset.gtmName =
                this.el.dataset.gtmName ||
                this.video.querySelector('source').src
            this.controls.play.addEventListener('click', this.playVideo)
            this.controls.pause.addEventListener('click', this.pauseVideo)
        }
    }

    playVideo = () => {
        this.player.play()
        this.el.classList.add(this.activeClass)
        makeGtmCall(this.el)
    }

    pauseVideo = () => {
        this.player.pause()
        this.el.classList.remove(this.activeClass)
    }
}
