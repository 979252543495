import Bowser from 'bowser'
export const isBrowserSupported = () => {
    const browser = Bowser.getParser(window.navigator.userAgent)
    const browserObj = browser.getBrowser()

    const browsers = {
        Chrome: 115,
        Edge: 115,
        Firefox: 116,
        Safari: 16
    }

    // if not one of the above browsers
    if (!(browserObj.name in browsers)) {
        return false
    } else {
        // if not version lower than last 2 versions
        for (const [key, value] of Object.entries(browsers)) {
            if (
                browserObj.name === key &&
                parseFloat(browserObj.version) < value - 2
            ) {
                return false
            }
        }
    }

    return true
}
