/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import Tooltip from './modules/tooltip'
_('tooltip', 'element').nodes().map((el) => new Tooltip(el))
*/
import tippy from 'tippy.js'
import { _ } from '../utils'

class Tooltip {
    constructor(el) {
        this.el = el
        this.trigger = _('tippy').nodeFrom(this.el)
        this.tip = _('tip', 'element').nodeFrom(this.el)

        tippy(this.trigger, {
            trigger: 'click',
            content: this.tip.innerHTML,
            allowHTML: true,
            animation: 'shift-toward-subtle',
            interactive: true,
            maxWidth: 290,
            placement: 'auto'
        })
    }
}
export default Tooltip
