/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import KnowledgeBase from './modules/knowledge-base'
_('knowledge-base').nodes().map((el) => new KnowledgeBase(el))
----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
data-behaviour="knowledge-base" - Main element
*/

// import config from '../config/main'
// import { _ } from '../utils'

export default class KnowledgeBase {
    constructor(el) {
        this.el = el
        this.data = []
        this.loadInitialTopics()
    }

    loadInitialTopics() {
        fetch('/api/knowledge-tree/')
            .then((resp) => {
                if (!resp.ok) {
                    throw new Error('Network response was not ok')
                }
                return resp.json()
            })
            .then((data) => {
                this.data = data
                const ul = this.renderTopics(this.data)
                this.el.appendChild(ul)
            })
            .catch((error) => {
                console.error('Error fetching knowledge tree:', error)
            })
    }

    renderTopics(data) {
        const ul = document.createElement('ul')

        if (data.type === 'folder') {
            data.topics.forEach((topic) => {
                const li = document.createElement('li')
                const button = document.createElement('button')
                button.classList.add('article-tree__button')
                button.textContent = topic.name
                button.setAttribute('topic-id', topic.id)

                button.addEventListener('click', () => {
                    const siblingUl = li.querySelector('ul')
                    if (siblingUl) {
                        siblingUl.classList.toggle('u-hidden')
                    } else {
                        const topicId = topic.id
                        fetch(`/api/knowledge-tree/${topicId}`)
                            .then((resp) => {
                                if (!resp.ok) {
                                    throw new Error(
                                        'Network response was not ok'
                                    )
                                }
                                return resp.json()
                            })
                            .then((data) => {
                                const nestedUl = this.renderTopics(data)
                                li.appendChild(nestedUl)
                            })
                            .catch((error) => {
                                console.error(
                                    'Error fetching knowledge tree for topic with ID',
                                    topicId,
                                    ':',
                                    error
                                )
                            })
                    }
                })

                li.appendChild(button)
                ul.appendChild(li)
            })
        } else if (data.type === 'pages') {
            data.pages.forEach((page) => {
                const pageLi = document.createElement('li')
                const pageLink = document.createElement('a')
                pageLink.classList.add('article-tree__link')
                pageLink.textContent = page.name
                pageLink.setAttribute('href', page.url)
                pageLi.appendChild(pageLink)
                ul.appendChild(pageLi)
            })
        }

        return ul
    }
}
