/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import Accordion from './modules/accordion'
_('accordion', 'element').nodes().map((el) => new Accordion(el))
----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
data-element="accordion"           - the base element
data-behaviour="accordion-trigger" - toggles the class
data-element="accordion-target"    - has the class that gets toggled
*/

import config from '../config/main'
import { _ } from '../utils'
export default class Accordion  {
    constructor(el) {
        this.el = el
        this.triggers = this.el.querySelectorAll(_('accordion-trigger').str())
        this.details = _('accordion-details', 'element').nodesFrom(this.el)

        this.triggers.forEach((trigger) => {
            trigger.addEventListener('click', this.toggleAccordion)
        })
    }

    toggleAccordion = (e) => {
        this.details.forEach((detail) => {
            detail.classList.remove('accordion__details--open')
        })

        e.currentTarget.parentNode.classList.toggle('accordion__details--open')
    }
}
