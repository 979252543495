/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import CloseMessage from './modules/close-message'
_('message', 'element').nodes().map((el) => new CloseMessage(el))

*/
import { _ } from '../utils'

export default (el) => {
    el.dataset.enabled = el.dataset.enabled || 'false'
    if (el.dataset.enabled === 'true') {
        return false
    }
    el.dataset.enabled = 'true'
    const closeButton = _('close-message').nodeFrom(el)

    const closeMessage = () => {
        el.classList.add('u-hidden')
    }

    closeButton?.addEventListener('click', closeMessage)
}
