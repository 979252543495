import { _ } from '../utils'
export default (el) => {
    const tocConfig = {
        allowedHeadings: 'h2, h3, h4, h5, h6'
    }
    const tocContent = el.querySelector('.section--default .container')
    const tocOutput = _('toc-output', 'element').node()
    const headings = tocContent.querySelectorAll(tocConfig.allowedHeadings)
    const tocContainer = document.createElement('div')
    tocContainer.innerHTML =
        '<h2 class="sidebar__heading">In this article:</h2>'
    const tocList = document.createElement('ol') // Create the top-level ordered list
    let currentList = tocList
    let prevLevel = 2
    // console.log(headings)
    let count = 0
    headings.forEach((heading, i) => {
        const title = heading.innerText
        // console.log(heading.innerText)
        const headingId = title
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '')

        const link = `#${headingId}`
        const headingLevel = parseInt(heading.tagName.charAt(1), 10) // Extract heading level from tagName
        if (count === 0 && prevLevel !== headingLevel) {
            return
        }
        count++
        // skip if first heading is not h2

        const tocItem = document.createElement('li')
        tocItem.innerHTML = `<a class='sidebar__link' href='${link}'>${title}</a>`

        while (headingLevel < prevLevel) {
            // Decrease nesting level
            currentList = currentList.parentElement.parentElement
            prevLevel--
        }

        if (headingLevel > prevLevel) {
            // Increase nesting level
            const newList = document.createElement('ol')
            currentList.lastChild.appendChild(newList)
            currentList = newList
        }

        currentList.appendChild(tocItem)
        heading.setAttribute('id', headingId)
        prevLevel = headingLevel
    })

    if (headings.length) {
        tocContainer.appendChild(tocList)
        tocContainer.innerHTML = `<div class="sidebar"> ${tocContainer.innerHTML}</div>`
        tocOutput.innerHTML = tocContainer.innerHTML
    }
}
