/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
yarn add axios
import Listing from './modules/listing'
_('listing').nodes().map((el) => new Listing(el))

----------------------------------------------------------------------------//
Detail:
----------------------------------------------------------------------------//
every input/select with data-element="input"  will be iterated through to add to the endpoint url using the name="category" value="news" for values in makeEndpointUrl()
e.g `apiurl/?category=news&name2=value2`

----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
data-behaviour="listing"                     root element
data-behaviour="endpoint-input"              on change will take the value and do an api call regardless of other select/inputs values
data-behaviour="go"                          on click will make makeEndpointUrl
data-behaviour="go-to" data-endpoint="/url/" on click will make that api call
data-behaviour="clear-form"                  clears the form and getsResults
data-element="input"                         on change triggers makeEndpointUrl() and resets the pagination to 1 if it isn't the pagination that was the change trigger

*/
import config from '../config/main'
import { _ } from '../utils'
import { handleError } from '../utils/error-handler'
import { debounce } from '../utils/debounce'
import axios from 'axios'
import loading from '../templates/loading'
import card from '../templates/card'

export default (el) => {
    const urlCheck = el.dataset.urlCheck ?? 'true'
    const onLoad = el.dataset.onload ?? 'true'
    const form = _('form', 'element').nodeFrom(el)
    let scrollUp = false
    let recentCalls = false
    let loadNo = 1
    let callTimer
    let makeEndpointUrl = false
    const startTimer = () => {
        callTimer = setTimeout(() => {
            recentCalls = false
            makeEndpointUrl()
        }, 800)
    }
    const pagination = {
        container: _('pagination', 'element').nodeFrom(el),
        input: _('pagination-input').nodeFrom(el),
        select: _('pagination-select').nodeFrom(el),
        nextPageTrigger: _('next-page').nodeFrom(el),
        previousPageTrigger: _('previous-page').nodeFrom(el),
        totalPages: _('total-pages', 'element').nodeFrom(el)
    }
    const container = {
        results: _('results-container', 'element').nodeFrom(el),
        noResults: _('no-results', 'element').nodeFrom(el)
    }
    const input = {
        els: _('input', 'element').nodesFrom(el),
        endpoint: _('endpoint-input').nodeFrom(el),
        search: _('search-input').nodeFrom(el),
        clear: _('clear-form').nodeFrom(el)
    }

    const goToUrls = _('go-to-url').nodesFrom(el)
    const gos = _('go').nodesFrom(el)

    const reEnableModules = () => {}
    const scrollToResultsTop = () => {
        if (scrollUp) {
            window.scrollTo({
                top:
                    container.results.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    30,
                behavior: 'smooth'
            })
        }
        scrollUp = false
    }
    const handleSuccessTypeCard = (response) => {
        let resultsHTML = ''
        if (response.data) {
            resultsHTML += '<div class="grid grid--3-col">'
            response.data.forEach((element, i) => {
                const item = card(response.data[i])
                resultsHTML += item
            })
            resultsHTML += '</div>'
        }

        container.results.innerHTML = resultsHTML
        reEnableModules()
        scrollToResultsTop()
    }

    const resetPaginator = () => {
        if (pagination.input) {
            pagination.input.value = 1
        }
    }

    const showLoading = () => {
        if (container.noResults) {
            container.noResults.classList.add('u-hidden')
        }
        container.results.innerHTML = loading()
    }

    const hideLoading = () => {
        container.results.innerHTML = ''
    }

    const changeParams = () => {
        if (urlCheck === 'true') {
            const url = new URL(window.location)
            const params = new URLSearchParams(url.search)
            let modalParam = ''
            if (params.has('modal')) {
                modalParam = `&modal=${params.get('modal')}`
            }

            window.history.replaceState(
                null,
                null,
                `${window.location.pathname}?${
                    input.endpoint.value.split('?')[1]
                }${modalParam}${window.location.hash}`
            )
        }
    }
    const buildPagination = (response) => {
        if (!response || !pagination?.container) {
            return false
        }
        const totalPages =
            response.pagination?.totalPages ?? response.total_pages
        const currentPage = response.pagination?.currentPage ?? response.page

        if (!totalPages || totalPages < 2) {
            pagination.container.classList.add('u-hidden')
            return false
        } else {
            pagination.container.classList.remove('u-hidden')
        }
        let selectOptions = ''
        for (let i = 1; i <= totalPages; i++) {
            selectOptions += `<option value="${i}" ${
                currentPage === i ? 'selected' : ''
            }>${i}</option>`
        }

        pagination.select.innerHTML = selectOptions
        pagination.totalPages.innerHTML = totalPages
        pagination.previousPageTrigger.disabled = currentPage === 1
        pagination.nextPageTrigger.disabled = currentPage === totalPages
    }

    const handleNoResults = () => {
        container.noResults.classList.remove('u-hidden')
        hideLoading()
    }

    const handleSuccess = (response) => {
        hideLoading()
        if (el.dataset.type === 'card') {
            return handleSuccessTypeCard(response)
        }
        return console.warn('missing data type')
    }

    const getResults = () => {
        showLoading()
        axios({
            method: el.dataset.action || 'get',
            url: input.endpoint.value
        })
            .then((response) => {
                changeParams()
                buildPagination(response.data)

                if (response.status !== 200) {
                    return handleError(response)
                }

                if (
                    !response.data.length &&
                    !Object.keys(response.data).length
                ) {
                    return handleNoResults()
                }

                return handleSuccess(response.data)
            })
            .catch((error) => {
                handleError(error, container.response)
            })
    }

    makeEndpointUrl = () => {
        clearTimeout(callTimer)
        if (recentCalls) {
            startTimer()
            return false
        }
        recentCalls = true
        showLoading()
        if (!(scrollUp || loadNo === 1)) {
            resetPaginator()
        }
        const startEndpoint = input.endpoint.value.split('?')
        let endpoint = startEndpoint[0]
        const inputs = _('input', 'element').nodesFrom(el)

        inputs?.forEach((inputItem, i) => {
            const name = inputItem.getAttribute('name')

            if (name === null) {
                if (config.debug) {
                    console.warn('input without a name', inputItem)
                }
                return false
            }
            if (!i) {
                endpoint += '?'
            }
            if (inputItem.type !== 'radio' && inputItem.type !== 'checkbox') {
                endpoint += `&${name}=${inputItem.value}`
            }
            if (inputItem.type === 'radio' && inputItem.checked) {
                endpoint += `&${name}=${
                    inputItem.value !== 'on'
                        ? inputItem.value
                        : inputItem.checked
                }`
            }
            if (inputItem.type === 'checkbox' && inputItem.checked) {
                endpoint += `&${name}=${
                    inputItem.value !== 'on'
                        ? inputItem.value
                        : inputItem.checked
                }`
            }
        })

        endpoint = endpoint.replace('?&', '?')
        input.endpoint.value = encodeURI(endpoint)

        loadNo++
        getResults(false)
        resetPaginator()
    }

    const goNextPage = () => {
        pagination.input.value++
        scrollUp = true
        makeEndpointUrl()
    }

    const goPreviousPage = () => {
        pagination.input.value--
        scrollUp = true
        makeEndpointUrl()
    }

    const changePaginationInput = () => {
        pagination.input.value = pagination.select.value
        scrollUp = true
        makeEndpointUrl()
    }

    const changeInputsToParams = () => {
        const url = new URL(window.location)
        const params = new URLSearchParams(url.search)
        let paramsPresent = false
        params.forEach((value, key) => {
            if (key === 'null' || key === 'modal' || value === 'null') {
                if (config.debug) {
                    console.warn('you have a null key/value')
                }
                return false
            }

            paramsPresent = true

            const inputEl = el.querySelector(`[name="${key}"]`)
            const radioInput = el.querySelector(`[value="${value}"]`)
            if (inputEl?.type === 'checkbox') {
                inputEl.checked = true
            }
            if (inputEl?.type === 'radio') {
                radioInput.checked = true
            }
            if (
                inputEl?.type === 'text' ||
                inputEl?.type === 'number' ||
                inputEl?.type === 'select-one'
            ) {
                inputEl.value = value
            }
            if (inputEl?.type === 'search') {
                inputEl.value = value
            }
        })
        if (paramsPresent) {
            return makeEndpointUrl()
        }
        if (!paramsPresent && onLoad === 'true') {
            makeEndpointUrl()
        }
    }

    const search = (e) => {
        if (e.target.value.length > 2 || e.target.value.length === 0) {
            resetPaginator()
            makeEndpointUrl()
        }
    }

    const formSubmit = (e) => {
        e.preventDefault()
        makeEndpointUrl()
    }

    const clearForm = () => {
        form.reset()
    }

    const setEndpointUrl = (e) => {
        input.endpoint.value = e.currentTarget.dataset.endpoint
        getResults()
    }

    // Event listeners
    goToUrls.forEach((goto) => {
        goto.addEventListener('click', setEndpointUrl)
    })

    gos.forEach((go) => {
        go.addEventListener('click', makeEndpointUrl)
    })

    if (input.clear) {
        input.clear.addEventListener('click', clearForm)
    }

    input.els.forEach((inputElement) => {
        inputElement.addEventListener('change', makeEndpointUrl)
    })

    if (input.search) {
        input.search.addEventListener('input', debounce(search, 500))
    }
    input.endpoint.addEventListener('change', getResults)
    pagination.nextPageTrigger?.addEventListener('click', goNextPage)
    pagination.previousPageTrigger?.addEventListener('click', goPreviousPage)
    pagination.select?.addEventListener('input', changePaginationInput)
    form.addEventListener('submit', formSubmit)
    if (urlCheck === 'true') {
        changeInputsToParams()
    }

    if (onLoad === 'true' && urlCheck === 'false') {
        makeEndpointUrl()
    }
}
