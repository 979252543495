import { _ } from '../utils'

export default class ChiliPiper {
    constructor(el) {
        console.info('chili piper module', el.id)
        function q(a) {
            return function() {
                ChiliPiper[a].q = (ChiliPiper[a].q || []).concat([arguments])
            }
        }
        window.ChiliPiper = window.ChiliPiper || 'submit scheduling showCalendar submit widget bookMeeting'.split(' ').reduce(
            function(a,b) {
                a[b] = q(b)
                return a
            }, {}
        )
        window.ChiliPiper.scheduling('akumina', 'new-website-inbound_router', {
            formId: el.id,
            title: 'Thanks! What time works best for a quick call?'
        })
    }
}
