const template = (data, classes) => `
<div class="message ${classes ?? 'message--error'} u-mb" data-element="message">
    <div>
        ${data}
    </div>
    <div>
        <button class="close message__close" type="button" data-behaviour="close-message">
            <span class="sr-only">close</span>
        </button>
    </div>
</div>
`
export default template
