/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
yarn tiny-slider
import Slider from './modules/slider'
_('slider').nodes().map((el) => new Slider(el))

----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
// slider options: https://github.com/ganlanyuan/tiny-slider#options
*/

import { _ } from './../utils'

import { tns } from 'tiny-slider/src/tiny-slider'
export default (el) => {
    const controls = _('controls', 'element').nodeFrom(el)
    const container = _('container', 'element').nodeFrom(el)
    const colCount = el.dataset.col ? el.dataset.col.split(',') : false
    const disable = el.dataset.disable ? el.dataset.disable.split(',') : false
    const gap = el.dataset.gap ? el.dataset.gap.split(',') : false
    const initSlider = () => {
        tns({
            container: container,
            items: colCount[0] ?? 1,
            disable: disable[0] === '1',
            slideBy: 1,
            loop: false,
            gutter: gap[1] ?? '30',
            mouseDrag: true,
            navPosition: 'bottom',
            controls: controls ? true : false,
            controlsContainer: controls ?? false,
            responsive: {
                568: {
                    items: colCount[1] ?? 2,
                    disable: disable[1] === '1',
                    gutter: gap[1] ?? '10'
                },
                768: {
                    items: colCount[2] ?? 3,
                    disable: disable[2] === '1',
                    gutter: gap[2] ?? '30'
                },
                1024: {
                    items: colCount[3] ?? 4,
                    disable: disable[3] === '1',
                    gutter: gap[3] ?? '30'
                }
            }
        })
        el.classList.add('slider--init')
    }
    initSlider()
}
