//  Sentry
import sentry from './modules/sentry.js'
sentry()

// Dependencies
import { _ } from './utils'
import { loadProgressBar } from 'axios-progress-bar'
import Accordion from './modules/accordion'
import ChiliPiper from './modules/chili-piper'
import LazyLoad from './modules/lazy-load'
import Listing from './modules/listing'
import MarketoForm from './modules/marketo-form'
import Menu from './modules/menu'
import Modal from './modules/modal'
import Slider from './modules/slider'
import SmoothScroll from './modules/smooth-scroll'
import Sticky from './modules/sticky'
import Tabs from './modules/tabs'
import ToggleClass from './modules/toggle-class'
import Tooltip from './modules/tooltip'
import Video from './modules/video'
import cmsBlocksPage from './modules/cms-blocks-page'
import hubspot from './modules/hubspot'
import selectFilter from './modules/select-filter'
import toc from './modules/toc'
import topLink from './modules/top-link'
import KnowledgeBase from './modules/knowledge-base'

loadProgressBar()

// prettier-ignore
{
    _('accordion').nodes().map((el) => new Accordion(el))
    _('cms-blocks-page').nodes().map((el) => cmsBlocksPage(el))
    _('image', 'lazyload').nodes().map((el) => new LazyLoad(el))
    _('knowledge-base')
        .nodes()
        .map((el) => new KnowledgeBase(el))
    _('listing').nodes().map((el) => new Listing(el))
    _('menu').nodes().map((el) => new Menu(el))
    _('modal').nodes().map((el) => new Modal(el))
    _('select-filter').nodes().map((el) => selectFilter(el))
    _('slider').nodes().map((el) => new Slider(el))
    _('sticky').nodes().map((el) => new Sticky(el))
    _('tab-trigger').nodes().map((el) => new Tabs(el))
    _('toc', 'element').nodes().map((el) => toc(el))
    _('toggle-class').nodes().map((el) => new ToggleClass(el))
    _('tooltip', 'element').nodes().map((el) => new Tooltip(el))
    _('top-link').nodes().map((el) => topLink(el))
    _('video', 'element').nodes().map((el) => new Video(el))
}

document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    new SmoothScroll(anchor)
})

//  third party load ins
// hubspot
const hubspotForms = _('hubspot', 'element').nodes()
const hubspotScript = document.createElement('script')
hubspotScript.id = 'hubspot-script'
if (!document.querySelector('#hubspot-script') && hubspotForms) {
    hubspotScript.src = 'https://js.hsforms.net/forms/embed/v2.js'
    document.body.appendChild(hubspotScript)
}

hubspotScript.onload = () => {
    hubspotForms.forEach((form) => hubspot(form))
}

// marketo
//  to be removed after end of september 2023
const marketoForms = _('marketo-form').nodes()
if (marketoForms) {
    const marketoJs = document.createElement('script')
    marketoJs.src = '//letsgo.akumina.com/js/forms2/js/forms2.min.js'
    document.body.appendChild(marketoJs)
    marketoJs.onload = () => {
        marketoForms.map((el) => new MarketoForm(el))
    }
}
//  to be removed after end of september 2023
const marketoHasChiliPiper = marketoForms.filter(
    (form) =>
        form.dataset.chiliPiper === 'true' || form.dataset.chiliPiper === '1'
)
if (marketoHasChiliPiper) {
    const chiliPiperScript = document.createElement('script')
    chiliPiperScript.src = 'https://js.chilipiper.com/marketing.js'
    document.body.appendChild(chiliPiperScript)

    chiliPiperScript.onload = () => {
        marketoHasChiliPiper.map((el) => new ChiliPiper(el, chiliPiperScript))
    }
}
