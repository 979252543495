export const setCookie = (cookieName, value, expiryDays) => {
    const date = new Date()
    date.setTime(date.getTime() + expiryDays * 24 * 60 * 60 * 1000)
    const expires = 'expires=' + date.toUTCString()
    document.cookie = cookieName + '=' + value + '; ' + expires
}

export const getCookie = (cookieName) => {
    const name = cookieName + '='
    const cookieArray = document.cookie.split(';')

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i]
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1)
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length)
        }
    }
    return ''
}
