/* global hbspt */
/* global dataLayer */

import { _ } from '../utils'

import { makeGtmCall } from './../utils/tracking'
import { setCookie } from './../utils/cookies'
export default (el) => {
    const data = {
        getFormId: el.dataset.getFormId,
        downloadButtonTarget: el.dataset.downloadButtonTarget,
        blockId: el.dataset.blockId,
        assetType: el.dataset.assetType,
        formName: el.dataset.formName,
        event: el.dataset.event,
        fileName: el.dataset.fileName,
        template: el.dataset.template
    }
    const modalParent = el.closest('.modal')

    const resourceHiddenEl = document.querySelector(
        `[data-id="${data.getFormId}"].resource-hidden`
    )
    let resourceHiddenElParent
    let resourceHiddenElParentClass
    if (resourceHiddenEl) {
        resourceHiddenElParent = document.querySelector(
            `[data-element="${resourceHiddenEl.dataset.parentModalContainer}"]`
        )
        resourceHiddenElParentClass =
            resourceHiddenEl.dataset.parentModalContainerClass
    }

    const hideAfterSuccessEl = document.querySelector(
        `[data-id="${data.getFormId}"].hide-after-success`
    )

    const gtmCalls = () => {
        // get all form fields
        const formFields = el.querySelectorAll('input:not([type="submit"])')

        // make object from form fields and values
        const formFieldsObj = {}
        formFields.forEach((field) => {
            const name = `gtm${field.name}`
            formFieldsObj[name] = field.value
        })
        const obj = {
            dataset: {
                gtmFormId: el.dataset.formId,
                ...formFieldsObj
            }
        }

        if (data.event !== '') {
            obj.dataset.gtmEvent = data.event
        }
        if (data.assetType !== '') {
            obj.dataset.gtmAssetType = data.assetType
        }
        if (data.template !== '') {
            obj.dataset.gtmBlockTemplate = data.template
        }
        if (data.formName !== '') {
            obj.dataset.gtmFormName = data.formName
        }
        if (data.fileName !== '') {
            obj.dataset.gtmFileName = data.fileName
        }

        makeGtmCall(obj)
    }

    hbspt?.forms.create({
        region: 'na1',
        portalId: '41730169',
        formId: el.dataset.formId,
        target: `#${el.id}`,
        onFormSubmit: () => {
            // set cookie for gate passed
            setCookie(`${data.blockId}-form-submitted`, true, 7)

            resourceHiddenEl?.classList.toggle('u-hidden')
            hideAfterSuccessEl?.classList.toggle('u-hidden')

            resourceHiddenElParent?.classList.toggle(
                resourceHiddenElParentClass
            )

            //  if download button click to trigger download straight away

            if (data.downloadButtonTarget) {
                const buttonTarget = _(
                    data.downloadButtonTarget,
                    'element'
                ).node()
                if (typeof dataLayer !== 'undefined') {
                    buttonTarget?.addEventListener('click', () => {
                        dataLayer?.push({
                            event: 'downloadEventName'
                        })
                    })
                }
                buttonTarget?.dispatchEvent(new Event('click'))
                window.location.href = buttonTarget?.href

            }
            // if inside modal close modal
            if (modalParent) {
                const trigger = _(modalParent.dataset.target, 'target').node()
                trigger?.dispatchEvent(new Event('click'))
            }

            if (el.dataset.redirectUrl) {
                window.location.href = el.dataset.redirectUrl
            }

            el.scrollIntoView()

            gtmCalls()
        }
    })
}
