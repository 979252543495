import { _ } from '../utils'

export default (el) => {
    const targetSelector = _(el.getAttribute('data-target'), 'element').node()
    const targetOptions = [...targetSelector.querySelectorAll('option')]

    const filterOptions = () => {
        if (
            !el.selectedOptions.length ||
            !el.selectedOptions[0].dataset.topics
        ) {
            return false
        }
        const topicsArray = el.selectedOptions[0].dataset.topics.split(',')
        targetOptions.forEach((item) => {
            item.style.display = 'none'
            if (topicsArray.includes(item.value) || item.value === '') {
                item.style.display = 'block'
            } else {
                if (item.selected) {
                    item.selected = false
                }
            }
        })
    }

    filterOptions()
}
