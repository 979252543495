class Selector {
    constructor(selector, attribute = 'behaviour') {
        this.main = (thunk) => thunk(attribute, selector)
    }
    str = () =>
        this.main((attribute, selector) => `[data-${attribute}="${selector}"]`)
    node = () => document.querySelector(this.str())
    nodeFrom = (el) => el.querySelector(this.str())
    nodes = () => [...document.querySelectorAll(this.str())]
    nodesFrom = (el) => [...el.querySelectorAll(this.str())]
}

export const _ = (selector, attribute = 'behaviour') => {
    return new Selector(selector, attribute)
}

/**
 *
 * @param {array} els - node array
 * @param {string} className - class name to toggle
 *
 */

export const toggleClasses = (els, className) => {
    els.map((el) => {
        el.classList.toggle(className)
    })
}

/**
 *
 * @param {array} els - node array
 * @param {string} className - class name to add
 *
 */

export const addClasses = (els, className) => {
    els.map((el) => {
        el.classList.add(className)
    })
}

/**
 *
 * @param {array} els - node array
 * @param {string} className - class name to add
 *
 */

export const removeClasses = (els, className) => {
    els.map((el) => {
        el.classList.remove(className)
    })
}
